<template>
  <div>
    <el-table
      ref="listTable"
      :data="tableData">
      <el-table-column
        prop="select_item"
        label="分类"
        min-width="150"
        align="center" />
      <el-table-column
        prop="username"
        label="用戶名"
        min-width="150"
        align="center" />
      <el-table-column
        prop="nickname"
        label="昵称"
        min-width="150"
        align="center" />
      <el-table-column
        label="类别"
        min-width="150"
        align="center">
        <template slot-scope="scope">
          {{ handleCategory(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="时间"
        min-width="150"
        align="center" />
      <el-table-column
        label="表单状态"
        min-width="150"
        align="center">
        <template slot-scope="scope">
          {{ handleState(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        fixed="right"
        min-width="200"
        align="center">
        <template slot-scope="scope">
          <el-button-group>
            <el-tooltip
              effect="dark"
              content="查看"
              placement="top">
              <el-button
                type="primary"
                icon="el-icon-view"
                @click="handleView(scope.row)" />
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="客服回覆"
              placement="top">
              <el-button
                :disabled="!editable"
                type="success"
                icon="el-icon-edit"
                @click="handleDialog(scope.row)" />
            </el-tooltip>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      class="table-view-modal"
      :visible.sync="dialogVisible"
      width="800px"
      lock-scroll
      modal
      close-on-click-modal
      destroy-on-close>
      <el-container>
        <el-header>
          <h3>客服回覆</h3>
        </el-header>
        <el-main>
          <div class="text-left">
            类别：{{ contain ? contain.select_item : '' }}<br><br>
            用户名：{{ contain ? contain.username : '' }}<br><br>
            昵称：{{ contain ? contain.nickname : '' }}<br><br>
            内容：{{ contain ? contain.content : '' }}<br><br>
            客服回覆<br><br>
            <el-input 
              maxlength="200"
              show-word-limit
              type="textarea" 
              :autosize="{ minRows: 3, maxRows: 6 }"
              v-model="contain.remark" />
          </div>
        </el-main>
        <el-footer>
          <el-button 
            type="primary" 
            @click="handleSaveData">
            回覆
          </el-button>
          <el-button 
            @click="dialogVisible = false">
            取消
          </el-button>
        </el-footer>
      </el-container>
    </el-dialog>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { contactChangeRemark, contactDetail } from '@/api/admin/contact'
import options from '@/utils/options'

export default {
  data () {
    return {
      dialogVisible: false,
      contain: {
        remark: null,
      },
    }
  },
  props: {
    tableData: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    },
  },
  methods: {
    handleCategory(row) {
      let data = ``
      options.categoryList.forEach(key => {
        if (key.value === row.category)
          data = key.label
      })
      return data
    },
    handleState(row) {
      let data = ``
      options.stateList.forEach(key => {
        if (key.value === row.state)
          data = key.label
      })
      return data
    },
    handleDialog(row) {
      this.dialogVisible = true
      contactDetail(row.id).then(res => {
        switch (res.status) {
          case resStatus.OK:
            this.contain = res.data
            this.$emit('handleRefresh')
            break
          default:
            this.$message.error(res.message)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    handleSaveData() {
      if (!this.contain.remark)
        return this.$message.error('请填写内容')

      contactChangeRemark(this.contain.id, this.contain.remark).then(res => {
        switch (res.status) {
          case resStatus.OK:
            this.$message.success('操作成功')
            this.$emit('handleRefresh')
            this.dialogVisible = false
            break
        }
        if (res.message.length !== 0) {
          this.$message(res.message)
        }
        this.handleEditable(true)
      }).catch(err => {
        this.$message.error(err)
        this.handleEditable(true)
      })
    },
    handleEditable (boo) {
      this.$emit('handleEditable', boo)
    },
    handleView (row) {
      contactDetail(row.id).then(res => {
        switch (res.status) {
          case resStatus.OK:
            this.$emit('handlePreview', res.data)
            this.$emit('handleRefresh')
            break
          default:
            this.$message.error(res.message)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .table-view-modal{
    max-width: 100%;
    .el-header {
      text-align: left;
      max-height: auto;
      h3 {font-size: 1.75em;}
    }
    .el-main {
      padding-top: 0;
    }
  }
</style>

<template>
  <el-card
    shadow="never" class="text-left">
    <el-form :inline="true" label-width="80px">
      <el-form-item>
        <el-input
          v-model="searchInput"
          placeholder="请输入关键字"
          suffix-icon="el-icon-search" />
      </el-form-item>
      <el-form-item
        label="查询时间">
        <el-date-picker
          v-model="searchFrom"
          type="date"
          placeholder="开始时间"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          align="left"
          style="width: 180px;" />
          至
        <el-date-picker
          v-model="searchTo"
          type="date"
          placeholder="結束时间"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          align="left"
          style="width: 180px;" />
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="searchState" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="handleRefresh">
          查询
        </el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '未回覆',
          value: 'not_replied',
        },
        {
          label: '已回覆',
          value: 'replied',
        },
      ]
    }
  },
  props: {
    state: {
      type: String,
      require: true
    },
    keyword: {
      type: String,
      require: true
    },
    from: {
      type: String,
      require: true
    },
    to: {
      type: String,
      require: true
    },
  },
  computed: {
    searchState: {
      set (str) {
        this.handleQuery({
          state: str
        })
      },
      get () {
        return this.state
      }
    },
    searchFrom: {
      set (str) {
        this.handleQuery({
          from: str
        })
      },
      get () {
        return this.from
      }
    },
    searchTo: {
      set (str) {
        this.handleQuery({
          to: str
        })
      },
      get () {
        return this.to
      }
    },
    searchInput: {
      set (str) {
        this.handleQuery({
          keyword: str
        })
      },
      get () {
        return this.keyword
      }
    },
  },
  methods: {
    handleQuery (obj) {
      this.$emit('handleQuery', obj)
    },
    handleRefresh () {
      this.handleQuery({
        page: 1
      })
      this.$emit('handleRefresh')
    }
  }
}
</script>
<style lang="scss" scoped>
.el-form-item{margin-bottom: 0!important;}
</style>

import request from '@/utils/axios'

export function contactList (params) {
  return request({
    url: `/api/console/form/contact`,
    method: 'get',
    params: params
  })
}

export function contactDetail (id) {
  return request({
    url: `/api/console/form/contact/${id}`,
    method: 'get',
  })
}

export function contactChangeRemark (id, data) {
  return request({
    url: `/api/console/form/contact/${id}`,
    method: 'post',
    data: {
      remark: data
    }
  })
}